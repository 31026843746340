import React from 'react';
import { AttachmentsModalButton } from '../AttachmentsModalButton';

import { IsDisabled } from '../../../../../types';
import { ProjectNanoID } from '../../../../../main/projects/projectsTypes';
import { ModalSelectAttachmentsFieldControlRequiredProps } from './ModalSelectAttachmentsFieldControl.types';

interface ModalSelectAttachmentsFieldControlProps {
  value: string[];
  disabled?: IsDisabled;
  onChange: (value: string[]) => void;
  projectNanoId?: ProjectNanoID;
}

function ModalSelectAttachmentsFieldControl({
  value,
  disabled,
  className,
  i18nTitle,
  icon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  onChange,
  projectNanoId,
  withoutInitialFocus
}: ModalSelectAttachmentsFieldControlProps &
  ModalSelectAttachmentsFieldControlRequiredProps) {
  return (
    <AttachmentsModalButton
      value={value}
      disabled={disabled}
      className={className}
      i18nTitle={i18nTitle}
      icon={icon}
      i18nSubmitText={i18nSubmitText}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      onChange={onChange}
      projectNanoId={projectNanoId}
      withoutInitialFocus={withoutInitialFocus}
    />
  );
}

export default ModalSelectAttachmentsFieldControl;
