import React from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { PageNanoID } from '../../../../pages/pagesTypes';

import {
  FetchPageShowPageQueryResponse,
  FETCH_PAGE_SHOW_PAGE_QUERY
} from '../../../../pages/queries/fetchPageShowPage.query';

import { usePageShowPage } from '../../../../pages/hooks/usePageShowPage';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { PageCache } from '../../../../pages/PageCache';
import { stringsKeys } from '../../../../../locales/keys';
import { MARKDOWN_HELP_PAGE_NANOID } from '../../../../../config';

interface MarkdownHelpModalButtonProps {
  icon?: IconsEnum;
  className?: ClassName;
  iconClassName?: ClassName;
  i18nText?: I18nText;
}

function MarkdownHelpModalButton({
  icon,
  className,
  iconClassName,
  i18nText
}: MarkdownHelpModalButtonProps) {
  const { page, pageLoading, togglePageData, pageError } =
    usePageShowPage<FetchPageShowPageQueryResponse>({
      uuid: MARKDOWN_HELP_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(
        MARKDOWN_HELP_PAGE_NANOID as PageNanoID
      )
    });

  return (
    <SimpleModalButton
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      iconClassName={iconClassName || 'h-6 w-6'}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      i18nText={i18nText}
      i18nTitle={stringsKeys.helpWithTextFormatting}
      tooltipI18nText={stringsKeys.textFormattingHelp}
      isLoading={pageLoading}
      onOpen={togglePageData}
      icon={icon || IconsEnum.QUESTION_MARK_CIRCLE_OUTLINE}
      withCloseButton
      withoutCancelButton
      withoutSubmitButton
      withoutInitialFocus
    >
      <LoadingSkeleton loaded={!pageLoading}>
        <AlertMessage message={pageError} />
        <div
          className="p-4"
          dangerouslySetInnerHTML={{
            __html: page?.localizedBody
          }}
        />
      </LoadingSkeleton>
    </SimpleModalButton>
  );
}

export default MarkdownHelpModalButton;
