import React, { Fragment, memo } from 'react';
import size from 'lodash/size';

import { ProjectNanoID } from '../../../../../../projects/projectsTypes';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { CheckedAttachmentItems } from '../../../AttachmentsListItem';

import { words } from '../../../../../../../locales/keys';

import { useAttachmentsModalFilesList } from './hooks/useAttachmentsModalFilesList';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';
import { AttachmentsList } from '../../../AttachmentsList';
import { FoldersListItemFolder } from '../../../FoldersListItem';
import { FoldersListBreadCrumbs } from '../../../FoldersListBreadCrumbs';

interface AttachmentsModalFilesListProps {
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  checkedIds?: CheckedAttachmentItems;
  onChange?: (items: CheckedAttachmentItems) => void;
  breadCrumbsHistory?: FoldersListItemFolder[];
  currentBreadCrumb?: FoldersListItemFolder;
  changeCurrentFolder?: (folder?: FoldersListItemFolder) => void;
}

function AttachmentsModalFilesList({
  projectNanoId,
  taskNanoId,
  checkedIds,
  onChange,
  breadCrumbsHistory,
  currentBreadCrumb,
  changeCurrentFolder
}: AttachmentsModalFilesListProps) {
  const {
    fileAttachments,
    fileAttachmentsError,
    fileAttachmentsFetched,
    fileAttachmentsIsPlaceholderData,
    fileAttachmentsFetchingNextPage,
    hasNextFileAttachmentsPage,
    loadMoreFileAttachments,
    files,

    breadCrumbsI18nText,
    hrefItems,
    handleClickBack,
    handleClickBreadCrumbsHref,

    checkedAll,
    handleCheckAll,
    checkedHash,
    handleSetCheckedIds
  } = useAttachmentsModalFilesList({
    projectNanoId,
    taskNanoId,
    checkedIds,
    onChange,
    breadCrumbsHistory,
    currentBreadCrumb,
    changeCurrentFolder
  });
  return (
    <Fragment>
      <FoldersListBreadCrumbs
        hrefItems={hrefItems}
        i18nText={breadCrumbsI18nText}
        checked={checkedAll}
        onCheck={handleCheckAll}
        checkable={size(fileAttachments) > 0}
        onClickBack={handleClickBack}
        onClickBreadCrumbsHref={handleClickBreadCrumbsHref}
      />
      <AlertMessage addClassName="m-4" message={fileAttachmentsError} />
      <LoadingSkeleton
        loaded={fileAttachmentsIsPlaceholderData || fileAttachmentsFetched}
        count={3}
      >
        <AttachmentsList
          items={files}
          onCheck={handleSetCheckedIds}
          checkedHash={checkedHash}
          checkable
          modalMode
        />
        <FetchMoreInfiniteButtonHelper
          errorMessage={fileAttachmentsError}
          isLoading={fileAttachmentsFetchingNextPage}
          hasNextPage={hasNextFileAttachmentsPage}
          i18nText={words.showMore}
          className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          wrapperClassName="text-center"
          placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
          onFetchMore={loadMoreFileAttachments}
        />
      </LoadingSkeleton>
    </Fragment>
  );
}

export default memo<AttachmentsModalFilesListProps>(AttachmentsModalFilesList);
