import React from 'react';
import { Controller, FieldPath } from 'react-hook-form';

import { IsDisabled } from '../../../types';
import { ProjectNanoID } from '../../../main/projects/projectsTypes';
import { ModalSelectAttachmentsFieldRequiredProps } from './ModalSelectAttachmentsField.types';

import {
  ModalSelectAttachmentsFieldControl,
  ModalSelectAttachmentsFieldControlRequiredProps
} from './components/ModalSelectAttachmentsFieldControl';

interface ModalSelectAttachmentsFieldProps<T> {
  name: FieldPath<T>;
  disabled?: IsDisabled;
  projectNanoId?: ProjectNanoID;
}

function ModalSelectAttachmentsField<T>({
  control,
  name,
  disabled,
  className,
  i18nTitle,
  icon,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  projectNanoId,
  withoutInitialFocus
}: ModalSelectAttachmentsFieldProps<T> &
  ModalSelectAttachmentsFieldRequiredProps<T> &
  ModalSelectAttachmentsFieldControlRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ModalSelectAttachmentsFieldControl
          value={value as string[]}
          disabled={disabled}
          className={className}
          i18nTitle={i18nTitle}
          icon={icon}
          modalIcon={modalIcon}
          i18nSubmitText={i18nSubmitText}
          tooltipPlacement={tooltipPlacement}
          tooltipI18nText={tooltipI18nText}
          onChange={onChange}
          projectNanoId={projectNanoId}
          withoutInitialFocus={withoutInitialFocus}
        />
      )}
    />
  );
}

export default ModalSelectAttachmentsField;
