import React, { useCallback, useEffect, useState } from 'react';
import size from 'lodash/size';
import keys from 'lodash/keys';
import flattenDeep from 'lodash/flattenDeep';

import { SimpleModalButton } from '../../../../buttons/SimpleModalButton';
import { AttachmentsModalAttachmentsList } from '../../../../../main/common/components/lists/AttachmentsModalAttachmentsList';

import { IsDisabled } from '../../../../../types';
import { AttachmentsModalButtonCommonProps } from './AttachmentsModalButton.types';
import { ProjectNanoID } from '../../../../../main/projects/projectsTypes';
import { CheckedAttachmentItems } from '../../../../../main/common/components/lists/AttachmentsListItem';
import { FileAttachmentID } from '../../../../../main/fileAttachments/fileAttachmentsTypes';

interface AttachmentsModalButtonProps {
  value: FileAttachmentID[];
  disabled?: IsDisabled;
  onChange: (value: FileAttachmentID[]) => void;
  projectNanoId?: ProjectNanoID;
}

const initialFiles = {};

function AttachmentsModalButton({
  value,
  disabled,
  onChange,
  className,
  i18nTitle,
  icon,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  projectNanoId,
  withoutInitialFocus
}: AttachmentsModalButtonProps & AttachmentsModalButtonCommonProps) {
  const [uploadedFiles, setUploadedFiles] =
    useState<CheckedAttachmentItems>(initialFiles);
  const [uploadedFilesBeforeOpen, setUploadedFilesBeforeOpen] =
    useState<CheckedAttachmentItems>(initialFiles);
  const [uploadedFileIds, setUploadedFileIds] =
    useState<FileAttachmentID[]>(value);
  const [uploadedFileIdsBeforeOpen, setUploadedFileIdsBeforeOpen] =
    useState<FileAttachmentID[]>(value);

  const handleOpen = useCallback<() => void>(() => {
    setUploadedFilesBeforeOpen(uploadedFiles);
    setUploadedFileIdsBeforeOpen(uploadedFileIds);
  }, [
    uploadedFiles,
    uploadedFileIds,
    setUploadedFilesBeforeOpen,
    setUploadedFileIdsBeforeOpen
  ]);

  const handleCancel = useCallback<() => void>(() => {
    setUploadedFiles(uploadedFilesBeforeOpen);
    setUploadedFileIds(uploadedFileIdsBeforeOpen);
  }, [
    uploadedFilesBeforeOpen,
    uploadedFileIdsBeforeOpen,
    setUploadedFiles,
    setUploadedFileIds
  ]);

  const handleAfterSubmit = useCallback<() => void>(() => {
    setUploadedFilesBeforeOpen(uploadedFiles);
    setUploadedFileIdsBeforeOpen(uploadedFileIds);
  }, [uploadedFileIds, uploadedFiles]);

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (onChange) {
      onChange(uploadedFileIds);
      handleAfterSubmit();
    }
  }, [onChange, uploadedFileIds, handleAfterSubmit]);

  const handleChangeSelectedFiles = useCallback<
    (files: CheckedAttachmentItems) => void
  >(
    (files) => {
      const currentFolder = keys(files)[0];
      setUploadedFiles((prev) => {
        const newFiles = { ...prev };
        newFiles[currentFolder] = files[currentFolder];
        return newFiles;
      });
    },
    [setUploadedFiles]
  );

  useEffect(() => {
    const ids = keys(uploadedFiles).map((folder) => uploadedFiles[folder]);
    setUploadedFileIds(flattenDeep(ids));
  }, [uploadedFiles]);

  return (
    <SimpleModalButton
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      className={className}
      i18nTitle={i18nTitle}
      icon={icon}
      modalIcon={modalIcon}
      i18nSubmitText={i18nSubmitText}
      isLoading={disabled}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      buttonText={size(value) > 0 ? `(${size(value)})` : null}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      modalSize="4xl"
      withoutInitialFocus={withoutInitialFocus}
    >
      <AttachmentsModalAttachmentsList
        projectNanoId={projectNanoId}
        onChange={handleChangeSelectedFiles}
        checkedIds={uploadedFiles}
      />
    </SimpleModalButton>
  );
}

export default AttachmentsModalButton;
