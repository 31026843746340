import {
  FetchFileAttachmentsFilters,
  FetchFileAttachmentsSort,
  FetchFileAttachmentsLimit,
  FetchFileAttachmentsGqlQuery,
  FetchFileAttachmentsCacheKey
} from '../../fileAttachmentsTypes';

import {
  INITIAL_FILE_ATTACHMENTS_FILTERS,
  INITIAL_FILE_ATTACHMENTS_LIMIT,
  INITIAL_FILE_ATTACHMENTS_SORT
} from '../../fileAttachmentsConstants';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

interface FileAttachmentsOptions {
  cacheKey: FetchFileAttachmentsCacheKey;
  query: FetchFileAttachmentsGqlQuery;
  initialFilters?: FetchFileAttachmentsFilters;
  initialSort?: FetchFileAttachmentsSort;
  initialLimit?: FetchFileAttachmentsLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'fileAttachments';

function useFileAttachments<
  FileAttachmentItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_FILE_ATTACHMENTS_FILTERS,
  initialSort = INITIAL_FILE_ATTACHMENTS_SORT,
  initialLimit = INITIAL_FILE_ATTACHMENTS_LIMIT,
  options = {}
}: FileAttachmentsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems
  } = useInfiniteIndexQuery<FileAttachmentItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    fileAttachmentsData: data,
    fileAttachments: items,
    fileAttachmentsError: itemsError,
    fileAttachmentsTotalCount: itemsTotalCount,
    fileAttachmentsFetched: isFetched,
    fileAttachmentsLoading: isLoading,
    fileAttachmentsFetchingNextPage: isFetchingNextPage,
    fileAttachmentsIsPlaceholderData: isPlaceholderData,
    fileAttachmentsFilters: currentFilters,
    fileAttachmentsSort: currentSort,
    fileAttachmentsPage: currentPage,
    fileAttachmentsLimit: currentLimit,
    hasNextFileAttachmentsPage: hasNextPage,
    updateFileAttachmentCache: updateItemCache,
    loadMoreFileAttachments: loadMoreItems,
    filterFileAttachments: filterItems,
    clearFileAttachmentsFilters: clearItemsFilters,
    sortFileAttachments: sortItems,
    limitFileAttachments: limitItems
  };
}

export default useFileAttachments;
