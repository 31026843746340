import { useCallback, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ProjectNanoID } from '../../../../../../../../projects/projectsTypes';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';
import { FileAttachmentID } from '../../../../../../../../fileAttachments/fileAttachmentsTypes';
import {
  BreadcrumbsUrl,
  BreadcrumbsUrls,
  I18nText
} from '../../../../../../../../../types';

import {
  FetchFileAttachmentsQueryResponse,
  FETCH_FILE_ATTACHMENTS_QUERY
} from '../../../../../../../../fileAttachments/queries/fetchFileAttachments.query';

import { useTableCheckable } from '../../../../../../../../../common/hooks/useTableCheckable';
import { useFileAttachments } from '../../../../../../../../fileAttachments/hooks/useFileAttachments';

import { AttachmentsListAttachments } from '../../../../../AttachmentsList';
import { FoldersListItemFolder } from '../../../../../FoldersListItem';
import { CheckedAttachmentItems } from '../../../../../AttachmentsListItem';

import { Files } from '../../../../../../../../../utils/Files';

import { ProjectPath } from '../../../../../../../../projects/ProjectPath';
import { TaskPath } from '../../../../../../../../tasks/TaskPath';
import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';

import {
  projectsKeys,
  tasksKeys,
  words
} from '../../../../../../../../../locales/keys';

interface AttachmentsModalFilesListOptions {
  projectNanoId: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  checkedIds?: CheckedAttachmentItems;
  onChange?: (items: CheckedAttachmentItems) => void;
  breadCrumbsHistory?: FoldersListItemFolder[];
  currentBreadCrumb?: FoldersListItemFolder;
  changeCurrentFolder?: (folder?: FoldersListItemFolder) => void;
}

function useAttachmentsModalFilesList({
  projectNanoId,
  taskNanoId,
  checkedIds,
  onChange,
  breadCrumbsHistory,
  currentBreadCrumb,
  changeCurrentFolder
}: AttachmentsModalFilesListOptions) {
  const {
    fileAttachments,
    fileAttachmentsError,
    fileAttachmentsFetched,
    fileAttachmentsIsPlaceholderData,
    fileAttachmentsFetchingNextPage,
    hasNextFileAttachmentsPage,
    loadMoreFileAttachments,
    updateFileAttachmentCache
  } = useFileAttachments<FetchFileAttachmentsQueryResponse>({
    cacheKey: taskNanoId
      ? TaskCache.fileAttachmentsCacheKey(taskNanoId)
      : ProjectCache.fileAttachmentsCacheKey(projectNanoId),
    query: FETCH_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      projectNanoId: taskNanoId ? undefined : projectNanoId,
      taskNanoId: taskNanoId ? taskNanoId : undefined,
      ext: { notIn: Files.imageExtensions }
    },
    options: {
      enabled: false
    }
  });

  const {
    fileAttachments: imageAttachments,
    fileAttachmentsError: imagesError,
    fileAttachmentsFetched: imagesFetched,
    fileAttachmentsIsPlaceholderData: imagesPlaceholderData,
    fileAttachmentsFetchingNextPage: imagesFetchingNextPage,
    hasNextFileAttachmentsPage: hasNextImageAttachmentsPage,
    loadMoreFileAttachments: loadMoreImageAttachments,
    updateFileAttachmentCache: updateImageAttachmentCache
  } = useFileAttachments<FetchFileAttachmentsQueryResponse>({
    cacheKey: taskNanoId
      ? TaskCache.imagesCacheKey(taskNanoId)
      : ProjectCache.imagesCacheKey(projectNanoId),
    query: FETCH_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      projectNanoId: taskNanoId ? undefined : projectNanoId,
      taskNanoId: taskNanoId ? taskNanoId : undefined,
      ext: { in: Files.imageExtensions }
    }
  });

  const itemsFoldersFiles = useMemo<AttachmentsListAttachments>(
    () =>
      fileAttachments.map((item) => ({
        id: item.id,
        fileUuid: item.uuid,
        name: item.name,
        href: item.file,
        target: '_blank',
        date: item.createdAt,
        icon: IconsEnum.BLANK,
        user: item.user,
        size: item.size
      })),
    [fileAttachments]
  );

  const itemsFoldersImages = useMemo<AttachmentsListAttachments>(
    () =>
      imageAttachments.map((item) => ({
        id: item.id,
        fileUuid: item.uuid,
        name: item.name,
        href: item.file,
        target: '_blank',
        date: item.createdAt,
        icon: IconsEnum.BLANK,
        user: item.user,
        size: item.size
      })),
    [imageAttachments]
  );

  const {
    checkedHash,
    checkedItems,
    checkedAll,
    handleSetCheckedIds,
    handleCheckAll
  } = useTableCheckable({
    items:
      currentBreadCrumb.type === 'files'
        ? itemsFoldersFiles
        : itemsFoldersImages,
    checkedIds: checkedIds[currentBreadCrumb.id]
  });

  useEffect(() => {
    if (onChange) {
      const checkedIds: FileAttachmentID[] = checkedItems.map(
        (item) => item.id
      );
      onChange({ [currentBreadCrumb.id]: checkedIds });
    }
  }, [checkedItems, currentBreadCrumb, onChange]);

  const getbreadCrumbs = useCallback<
    () => { hrefItems: BreadcrumbsUrls; breadCrumbsI18nText: I18nText }
  >(() => {
    if (isEmpty(breadCrumbsHistory)) {
      return {
        hrefItems: [],
        breadCrumbsI18nText: projectNanoId
          ? projectsKeys.allAttachments
          : tasksKeys.allAttachments
      };
    }
    const hrefs = breadCrumbsHistory.map((item) => ({
      i18nText: item.i18nText || item.name,
      pathUrl: item.href
    }));
    hrefs.splice(-1);
    return {
      hrefItems: [
        {
          tooltipI18nText: words.allAttachments,
          icon: IconsEnum.PROJECTS,
          pathUrl: projectNanoId
            ? ProjectPath.attachments(projectNanoId)
            : TaskPath.attachments(taskNanoId)
        },
        ...hrefs
      ],
      breadCrumbsI18nText: currentBreadCrumb.i18nText || currentBreadCrumb.name
    };
  }, [breadCrumbsHistory, currentBreadCrumb, projectNanoId, taskNanoId]);

  const handleClickBack = useCallback<() => void>(() => {
    changeCurrentFolder(breadCrumbsHistory[breadCrumbsHistory.length - 2]);
  }, [breadCrumbsHistory, changeCurrentFolder]);

  const handleClickBreadCrumbsHref = useCallback<
    (href: BreadcrumbsUrl) => void
  >(
    (href) => {
      const selectedFolder = breadCrumbsHistory.find(
        (breadCrumb) => breadCrumb.href === href.pathUrl
      );

      changeCurrentFolder(selectedFolder);
    },
    [breadCrumbsHistory, changeCurrentFolder]
  );

  return {
    fileAttachments:
      currentBreadCrumb.type === 'files' ? fileAttachments : imageAttachments,
    fileAttachmentsError:
      currentBreadCrumb.type === 'files' ? fileAttachmentsError : imagesError,
    fileAttachmentsFetched:
      currentBreadCrumb.type === 'files'
        ? fileAttachmentsFetched
        : imagesFetched,
    fileAttachmentsIsPlaceholderData:
      currentBreadCrumb.type === 'files'
        ? fileAttachmentsIsPlaceholderData
        : imagesPlaceholderData,
    fileAttachmentsFetchingNextPage:
      currentBreadCrumb.type === 'files'
        ? fileAttachmentsFetchingNextPage
        : imagesFetchingNextPage,
    hasNextFileAttachmentsPage:
      currentBreadCrumb.type === 'files'
        ? hasNextFileAttachmentsPage
        : hasNextImageAttachmentsPage,
    loadMoreFileAttachments:
      currentBreadCrumb.type === 'files'
        ? loadMoreFileAttachments
        : loadMoreImageAttachments,
    files:
      currentBreadCrumb.type === 'files'
        ? itemsFoldersFiles
        : itemsFoldersImages,
    updateAttachmentCache:
      currentBreadCrumb.type === 'files'
        ? updateFileAttachmentCache
        : updateImageAttachmentCache,

    breadCrumbsI18nText: getbreadCrumbs().breadCrumbsI18nText,
    hrefItems: getbreadCrumbs().hrefItems,
    currentFolderHref: currentBreadCrumb?.href,
    handleClickBack,
    handleClickBreadCrumbsHref,

    checkedAll,
    handleCheckAll,
    checkedHash,
    handleSetCheckedIds
  };
}

export default useAttachmentsModalFilesList;
